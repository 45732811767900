import { QuestionFiltersType } from "hassibot/apps/pico/questions/components/question-list";
import { LogsViewType } from "hassibot/component/log";
import { prependUri, toQueryString } from "hassibot/services_v2/common";
import { isIssueRelated } from "hassibot/services_v2/common/guards";
import {
  AuditLogUuid,
  BroadcastUuid,
  CaregiverLog,
  CaregiverUuid,
  CgpcRelationUuid,
  CollaboratorGoogleID,
  ContactUuid,
  ContractUuid,
  DebriefedEventLogUuid,
  EventUuid,
  IssueUuid,
  MonetBankAccountUuid,
  MonetBillUuid,
  MonetPaymentIntentUuid,
  MonetPaymentUuid,
  MonetTaxCreditRequestUuid,
  PicoBadgeUuid,
  PrescriberLog,
  PrescriberUuid,
  ProclientLog,
  ProclientMemberUuid,
  ProclientUuid,
  RegularizationUuid,
  ReviewUuid,
  SharedHouseUuid,
  StructureUuid,
  ThirdPartyPayerUuid,
} from "hassibot/services_v2/common/types";
import { API_DATE_FORMAT } from "hassibot/util/date";
import { DateTime } from "luxon";
import { Moment } from "moment";
import { SignableListFilter } from "./apps/caregivers/components/documents-validation/documents-validation";
import { CHANNEL_ID_URL_PARAM } from "./apps/chat/utils/chat-v2.constants";
import { CaregiverAbsencesFiltersType } from "./apps/pico/caregiver-absences-list";
import { PrefilledDebriefType } from "./component/events/types";
import { GeoFilter, toString } from "./component/filter/geography";
import { isChildBill, MonetBillShort, MonetBillSource } from "./services_v2/monet/bills/types/bill";
import { UrlPlan } from "./services_v2/plans/types";

// CAREGIVERS

class CaregiverAppRoutes {
  list = () => "";
  candidatesList = () => "/candidates";
  recruitingPoolList = () => "/recruiting-pool";
  documentsValidation = (params?: Partial<SignableListFilter>) => {
    const urlPrefix = "/documents-validation";
    if (params) return `${urlPrefix}?${toQueryString(params)}`;
    else return urlPrefix;
  };
  create = () => "/create";
  view = (caregiverUuid: CaregiverUuid) => `/${caregiverUuid}`;
  edit = (caregiverUuid: CaregiverUuid) => `/${caregiverUuid}/edit`;
  questionList = (params?: Partial<QuestionFiltersType> | { sort: string }): string => {
    if (params) return `/questions?${toQueryString(params)}`;
    else return `/questions`;
  };

  // new caregiver edit sections
  editIdentity = (caregiverUuid: CaregiverUuid) => `/${caregiverUuid}/edit/identity`;
  editAdminInfo = (caregiverUuid: CaregiverUuid) => `/${caregiverUuid}/edit/admin-infos`;
  editContacts = (caregiverUuid: CaregiverUuid) => `/${caregiverUuid}/edit/contacts`;
  editContact = (caregiverUuid: CaregiverUuid, contactUuid: ContactUuid) =>
    `/${caregiverUuid}/edit/contacts/${contactUuid}`;
  newContact = (caregiverUuid: CaregiverUuid) => `/${caregiverUuid}/edit/contacts`;
  editActivity = (caregiverUuid: CaregiverUuid) => `/${caregiverUuid}/edit/activity`;
  editProfessionalProfile = (caregiverUuid: CaregiverUuid) =>
    `/${caregiverUuid}/edit/professional-profile`;

  editSkills = (caregiverUuid: CaregiverUuid) => `/${caregiverUuid}/edit/skills`;
  editInfos = (caregiverUuid: CaregiverUuid) => `/${caregiverUuid}/edit/caregiver-infos`;
  editPublicProfile = (caregiverUuid: CaregiverUuid) => `/${caregiverUuid}/edit/website-profile`;

  editReview = (caregiverUuid: CaregiverUuid, reviewUuid: ReviewUuid) =>
    `/${caregiverUuid}/edit/reviews/${reviewUuid}`;
  editReviews = (caregiverUuid: CaregiverUuid) => `/${caregiverUuid}/edit/reviews`;
  newReview = (caregiverUuid: CaregiverUuid) => `/${caregiverUuid}/edit/reviews`;

  logs = (uuid: CaregiverUuid, log?: CaregiverLog) => {
    const params: { viewType: LogsViewType } | null = log
      ? { viewType: isIssueRelated(log) ? "issues" : "classic" }
      : null;
    if (params) return `/${uuid}/logs?${toQueryString(params)}${log ? "#" + log : ""}`;
    else return `/${uuid}/logs${log ? "#" + log.uuid : ""}`;
  };
  logByUuid = (uuid: CaregiverUuid, logUuid: AuditLogUuid | DebriefedEventLogUuid) =>
    `/${uuid}/logs#${logUuid}`;

  availabilities = (uuid: CaregiverUuid) => `/${uuid}/availabilities`;
  documents = (uuid: CaregiverUuid) => `/${uuid}/documents`;
  planning = (uuid: CaregiverUuid) => `/${uuid}/planning`;
  textMessages = (uuid: CaregiverUuid) => `/${uuid}/text-messages`;
  contracts = (uuid: CaregiverUuid) => `/${uuid}/contracts`;
  proclientMemberRelations = (uuid: CaregiverUuid) => `/${uuid}/proclients-members-relations`;
  covcomp = (uuid: CaregiverUuid) => `/${uuid}/covcomp`;
  trainings = (uuid: CaregiverUuid) => `/${uuid}/trainings`;
  createBroadcast = () => `/broadcasts/create`;
  broadcastList = () => `/broadcasts`;
  broadcastView = (broadcastUuid: BroadcastUuid) => `/broadcasts/${broadcastUuid}`;
  qualityMonitoring = (uuid: CaregiverUuid) => `/${uuid}/quality-monitoring`;
  broadcastCreate = () => `/broadcasts/create`;
  geoTemporalSearchV2 = (hydrateWithProclientUuid?: ProclientUuid): string => {
    const params = hydrateWithProclientUuid ? { hydrateWithProclientUuid } : {};
    return `/geo-temporal-search-v2?${toQueryString(params)}`;
  };
  planEvent = (uuid: CaregiverUuid) => `/${uuid}/events/create`;
  debriefEvent = (uuid: CaregiverUuid, eventUuid?: EventUuid) =>
    `/${uuid}/events/debrief${eventUuid ? `?eventUuid=${eventUuid}` : ""}`;
  customerServiceTicketList = () => "/customer-service-tickets";
}

const caregiverAppRoutes = new CaregiverAppRoutes();
export const caregiverAppRouter = prependUri("/ia/caregivers")(caregiverAppRoutes);

// BADGES

class BadgeAppRoutes {
  view = (picoBadgeUuid: PicoBadgeUuid) => `/${picoBadgeUuid}`;
  badges = () => ``;
  synchronisation = (picoBadgeUuid: PicoBadgeUuid) => `/${picoBadgeUuid}/synchronize`;
}
const badgeAppRoutes = new BadgeAppRoutes();
export const badgeAppRouter = prependUri("/ia/pico/badges")(badgeAppRoutes);

class ChatAppRoutes {
  listRooms = () => ``;
  viewRoom = (caregiverUuid: CaregiverUuid) => `/${caregiverUuid}`;
}

const chatAppRoutes = new ChatAppRoutes();
export const chatAppRouter = prependUri("/ia/chat")(chatAppRoutes);

class ChatV2AppRoutes {
  home = () => ``;
  viewChannel = (channelId: CaregiverUuid) => {
    const urlParams = new URLSearchParams();
    urlParams.append(CHANNEL_ID_URL_PARAM, channelId);
    return `?${urlParams.toString()}`;
  };
}

const chatAppV2Routes = new ChatV2AppRoutes();
export const chatAppV2Router = prependUri("/ia/chat")(chatAppV2Routes);

// PROCLIENT

class ProclientAppRoutes {
  list = () => "";
  create = () => "/create";
  view = (uuid: ProclientUuid) => `/${uuid}`;
  logs = (uuid: ProclientUuid, log?: ProclientLog, viewType?: LogsViewType) => {
    const params: { viewType: LogsViewType } | {} = {
      ...(log ? { viewType: isIssueRelated(log) ? "issues" : "classic" } : {}),
      ...(viewType ? { viewType } : {}),
    };
    if (Object.keys(params).length > 0) {
      return `/${uuid}/logs?${toQueryString(params)}${log ? "#" + log.uuid : ""}`;
    } else {
      return `/${uuid}/logs`;
    }
  };
  edit = (uuid: ProclientUuid) => `/${uuid}/edit`;
  leads = () => `/leads`;
  surveyQaList = () => `/satisfaction-survey`;
  debriefEvent = (uuid: ProclientUuid, eventUuid?: EventUuid) =>
    `/${uuid}/events/debrief${eventUuid ? `?eventUuid=${eventUuid}` : ""}`;
  closeIssue = (uuid: ProclientUuid, issueUuid: IssueUuid) =>
    `/${uuid}/events/debrief${issueUuid ? `?issueUuid=${issueUuid}` : ""}`;
  debriefPrefilled = (uuid: ProclientUuid, prefilled?: PrefilledDebriefType) =>
    `/${uuid}/events/debrief${prefilled ? `?prefilled=${prefilled}` : ""}`;
  planEvent = (uuid: ProclientUuid) => `/${uuid}/events/create`;

  editInfos = (uuid: ProclientUuid, memberUuid?: ProclientMemberUuid) =>
    `/${uuid}/edit/members${memberUuid ? `/${memberUuid}` : "/create"}`;
  editInfosRoot = (uuid: ProclientUuid) => `/${uuid}/edit/members`;

  membersContactDetails = (uuid: ProclientUuid) => `/${uuid}/edit/contact-details`;

  editBankInfos = (uuid: ProclientUuid) => `/${uuid}/edit/bankinfos`;
  editOrigins = (uuid: ProclientUuid) => `/${uuid}/edit/origins`;
  editFinancialHelp = (uuid: ProclientUuid) => `/${uuid}/edit/financial-help`;
  editContact = (uuid: ProclientUuid, contactUuid?: ContactUuid) =>
    `/${uuid}/edit/contacts${contactUuid ? `/${contactUuid}` : "/create"}`;
  contacts = (uuid: ProclientUuid) => `/${uuid}/edit/contacts`;
  documents = (uuid: ProclientUuid) => `/${uuid}/documents`;
  contracts = (uuid: ProclientUuid) => `/${uuid}/contracts`;
  caregiverRelations = (uuid: ProclientUuid) => `/${uuid}/caregivers-relations`;
  operations = (uuid: ProclientUuid) => `/${uuid}/operations`;
  plans = (uuid: ProclientUuid) => `${this.operations(uuid)}#plans`;
  operationsEdit = (uuid: ProclientUuid) => `/${uuid}/operations/edit`;
  planning = (uuid: ProclientUuid) => `/${uuid}/planning`;
  assertions = (uuid: ProclientUuid) => `/${uuid}/assertions`;
  createIssue = (uuid: ProclientUuid) => `/${uuid}/issues/create`;
  // CONTACTS
  qualityMonitoring = (uuid: ProclientUuid) => `/${uuid}/quality-monitoring`;
  // OH +
  additionalServices = (uuid: ProclientUuid) => `/${uuid}/additional-services`;
  // Monet
  pricingStrategy = (uuid: ProclientUuid) => `/${uuid}/pricing-strategy`;
  monetBills = (uuid: ProclientUuid) => `/${uuid}/monet-bills`;
  editMonetPricingStrategy = (uuid: ProclientUuid) => `/${uuid}/monet/pricing-strategy/edit`;
  createMonetPricingStrategy = (uuid: ProclientUuid) => `/${uuid}/monet/pricing-strategy/create`;
  // Payment setting
  createPaymentSetting = (uuid: ProclientUuid, muuid: ProclientMemberUuid) =>
    `/${uuid}/monet/payment-setting/${muuid}/create`;
  editPaymentSetting = (uuid: ProclientUuid, muuid: ProclientMemberUuid) =>
    `/${uuid}/monet/payment-setting/${muuid}/edit`;
  mandateJourney = (
    uuid: ProclientUuid,
    proclientMemberUuid: ProclientMemberUuid,
    bankAccountUuid: MonetBankAccountUuid
  ) =>
    `/${uuid}/monet/payment-setting/${proclientMemberUuid}/bank-account/${bankAccountUuid}/mandate-journey`;
  // shared houses
  sharedHouse = (uuid: SharedHouseUuid) => `/shared-houses/${uuid}`;
  sharedHouseSplitPlanning = (uuid: SharedHouseUuid) => `/shared-houses/${uuid}/split-planning`;
  sharedHouseList = () => "/shared-houses";
  createSharedHouse = () => "/shared-houses/create";
  editSharedHouse = (uuid: SharedHouseUuid) => `/shared-houses/${uuid}/edit`;
  customerServiceTicketList = () => "/customer-service-tickets";
}

const proclientAppRoutes = new ProclientAppRoutes();
export const proclientAppRouter = prependUri("/ia/proclients")(proclientAppRoutes);

// PICO
class PicoAppRoutes {
  list = (qParams?: {
    geo?: GeoFilter[];
    periodType?: "DAILY" | "WEEKLY" | "MONTHLY";
    referenceDate?: DateTime;
    issueType?: "all" | "in_progress" | "issue" | "issue_major";
    caregiverUuid?: CaregiverUuid;
  }): string => {
    const params = qParams
      ? {
          ...(qParams.geo ? { agencies: toString(qParams.geo) } : {}),
          ...(qParams.periodType ? { periodType: qParams.periodType } : {}),
          ...(qParams.referenceDate
            ? { referenceDate: qParams.referenceDate.toFormat("yyyy-LL-dd") }
            : {}),
          ...(qParams.issueType ? { interventionStatus: qParams.issueType } : {}),
          ...(qParams.caregiverUuid ? { caregiverUuid: qParams.caregiverUuid } : {}),
        }
      : null;
    return `/pico/interventions${params ? `?${toQueryString(params)}` : ""}`;
  };
  debug = (): string => `/pico/interventions/debug`;
  resolutionMode = (geo?: GeoFilter[]): string => {
    const params = geo && geo.length > 0 ? { agencies: toString(geo) } : {};
    if (params) return `/pico/interventions/resolution-mode?${toQueryString(params)}`;
    else return `/pico/interventions/resolution-mode`;
  };
  details = (ximiId): string => `/pico/interventions/${ximiId}`;
  questionList = (params?: Partial<QuestionFiltersType> | { sort: string }): string => {
    if (params) return `/pico/questions?${toQueryString(params)}`;
    else return `/pico/questions`;
  };
  caregiverAbsenceList = (
    params?: Partial<CaregiverAbsencesFiltersType> | { sort: string }
  ): string => {
    if (params) return `/pico/absences?${toQueryString(params)}`;
    else return `/pico/absences`;
  };
}

const picoAppRoutes = new PicoAppRoutes();
export const picoAppRouter = prependUri("/ia")(picoAppRoutes);

//UPLOAD RETRY
export const uploadRetryAppRouter = prependUri("/ia/upload-retry")({
  index: () => ``,
});

// CONTRACTS

class CgpcAppRoutes {
  view = (contractUuid: ContractUuid) => `/${contractUuid}`;
  documents = (contractUuid: ContractUuid) => `/${contractUuid}/documents`;
  assertions = (contractUuid: ContractUuid) => `/${contractUuid}/assertions`;
}

const cgpcAppRoutes = new CgpcAppRoutes();
export const cgpcAppRouter = prependUri("/ia/cgpcs/contracts")(cgpcAppRoutes);

// RELATIONS

class CgpcRelationAppRoutes {
  view = (cgpcRelationUuid: CgpcRelationUuid) => `/${cgpcRelationUuid}`;
}

const cgpcRelationAppRoutes = new CgpcRelationAppRoutes();
export const cgpcRelationAppRouter = prependUri("/ia/cgpcs-relations")(cgpcRelationAppRoutes);

// PRESCRIBERS

class PrescriberRoutes {
  list = () => "";
  create = () => "/create";
  view = (uuid: PrescriberUuid) => `/${uuid}`;
  logs = (uuid: PrescriberUuid, log?: PrescriberLog) => `/${uuid}/logs${log ? "#" + log.uuid : ""}`;
  proclients = (uuid: PrescriberUuid) => `/${uuid}/proclients`;
  editDetails = (uuid: PrescriberUuid) => `/${uuid}/edit/details`;
  editContact = (uuid: PrescriberUuid) => `/${uuid}/edit/contact`;
  editAdvancedActivity = (uuid: PrescriberUuid) => `/${uuid}/edit/advanced-activity`;
  followupView = (periodType?: string, referenceDate?: Moment) =>
    periodType && referenceDate
      ? `/followup/${periodType.toLowerCase()}/${referenceDate.format(API_DATE_FORMAT)}`
      : "";

  structureList = () => "/structures";
  structureCreate = () => "/structures/create";
  structureView = (uuid: StructureUuid) => `/structures/${uuid}`;
  structureEdit = (uuid: StructureUuid) => `/structures/${uuid}/edit`;
  structureProclients = (uuid: StructureUuid) => `/structures/${uuid}/proclients`;
  structureLogs = (uuid: StructureUuid, log?: PrescriberLog) =>
    `/structures/${uuid}/logs${log ? "#" + log.uuid : ""}`;
  debriefEvent = (uuid: PrescriberUuid, eventUuid?: EventUuid) =>
    `/${uuid}/events/debrief${eventUuid ? `?eventUuid=${eventUuid}` : ""}`;
  planEvent = (uuid: PrescriberUuid) => `/${uuid}/events/create`;
}

const prescriberAppRoutes = new PrescriberRoutes();
export const prescriberAppRouter = prependUri("/ia/prescribers")(prescriberAppRoutes);

// COLLABORATOR

class CollaboratorAppRoutes {
  view = (collaboratorGoogleID: CollaboratorGoogleID) => `/${collaboratorGoogleID}`;
  list = () => "";
}
const collaboratorAppRoutes = new CollaboratorAppRoutes();
export const collaboratorAppRouter = prependUri("/ia/collaborators")(collaboratorAppRoutes);

// CAREGIVERS SEARCH V2

class AvsSearchAppRoutes {
  avsSearch = (hydrateWithProclientUuid?: ProclientUuid, hydrateWithPlan?: UrlPlan[]): string => {
    const params: Record<string, string> = {};
    if (hydrateWithProclientUuid) params["hydrateWithProclientUuid"] = hydrateWithProclientUuid;
    if (hydrateWithPlan) params["hydrateWithPlan"] = JSON.stringify(hydrateWithPlan);
    return params ? `?${toQueryString(params)}` : "";
  };
}

const avsSearchAppRoutes = new AvsSearchAppRoutes();
export const avsSearchAppRouter = prependUri("/ia/caregiver-search")(avsSearchAppRoutes);

class CompassAppRoutes {
  root = (): string => "";
  staffing = (): string => "/staffing";
  staffingMentalLoad = (): string => "/staffing-v2";
  pico = (): string => "/pico"; // Télégestion
  interventionFollowups = (): string => "/intervention-followups";
  customerServiceTicketsCoordo = (): string => "/customer-service-tickets-coordo";
  customerServiceTicketsRS = (): string => "/customer-service-tickets-rs";
  customerServiceTicketsRhCaregiver = (): string => "/customer-service-tickets-rh-caregiver";
  caregiverFollowups = (): string => "/caregiver-followups";
  caregiverTrainings = (): string => "/caregiver-trainings";
  caregiverAdminSection = (): string => "/caregiver-admin-section";
  thisWeekOverviewRS = (): string => "/this-week-overview-rs";
  thisWeekOverviewCoordo = (): string => "/this-week-overview-coordo";
  proclientFollowups = (): string => "/proclient-followups";
  caregiverRecruitment = (): string => "/caregiver-recruitment";
}

const compassAppRoutes = new CompassAppRoutes();
export const compassAppRouter = prependUri("/ia/compass")(compassAppRoutes);

export const notificationsAppRouter = prependUri("/ia/notifications")({ index: () => "" });

// Monet
class MonetAppRoutes {
  listRegularizations = (geo?: GeoFilter[]): string => {
    const params = {
      ...(geo ? { agencies: toString(geo) } : {}),
    };
    const hasKeys = Object.keys(params).length > 0;
    return `/regularizations${hasKeys ? `?${toQueryString(params)}` : ""}`;
  };
  createRegularization = (): string => `/regularizations/create`;
  editRegularization = (regularizationUuid: RegularizationUuid): string =>
    `/regularizations/${regularizationUuid}/edit`;
  viewRegularization = (regularizationUuid: RegularizationUuid): string =>
    `/regularizations/${regularizationUuid}`;
  listBills = (): string => `/bills`;
  listBillsSharedHouses = (): string => `/bills-shared-houses`;
  root = (): string => ``;
  viewPayment = (paymentUuid: MonetPaymentUuid): string => `/payments/${paymentUuid}`;
  listPayments = (): string => `/payments`;
  listBalances = (): string => `/balances`;
  createPayments = (): string => `/payments/create`;
  createRefunds = (): string => `/refunds/create`;
  listProclientsMembersPaymentsIntents = (): string => `/proclients-members-payments-intents`;
  viewProclientMemberPaymentIntent = (paymentIntentUuid: MonetPaymentIntentUuid): string =>
    `/proclients-members-payments-intents/${paymentIntentUuid}`;
  createProclientsMembersPaymentsIntents = (): string =>
    `/proclients-members-payments-intents/create`;
  listCaregiversPaymentsIntents = (): string => `/caregivers-payments-intents`;
  viewCaregiverPaymentIntent = (paymentIntentUuid: MonetPaymentIntentUuid): string =>
    `/caregivers-payments-intents/${paymentIntentUuid}`;
  viewBill = (billUuid: MonetBillUuid): string => `/bills/${billUuid}`;
  viewXimiBill = (billUuid: MonetBillUuid): string => `/ximi-bills/${billUuid}`;
  viewBillOrXimiBill = (bill: MonetBillShort): string => {
    if (bill.source === MonetBillSource.ximi) {
      return this.viewXimiBill(bill.uuid);
    }
    if (bill.parentVirtualBillUuid && isChildBill(bill.billType)) {
      return this.viewBill(bill.parentVirtualBillUuid);
    }
    return this.viewBill(bill.uuid);
  };
  editPayment = (paymentUuid: MonetPaymentUuid) => `/payments/${paymentUuid}/edit`;
  listTaxCreditPaymentRequests = (): string => `/tax-credit-payment-requests`;
  viewTaxCreditRequest = (taxCreditRequestUuid: MonetTaxCreditRequestUuid): string =>
    `/tax-credit-payment-requests/${taxCreditRequestUuid}`;
  listThirdPartyPayers = () => `/third-party-payers`;
  viewThirdPartyPayer = (thirdPartyPayerUuid: ThirdPartyPayerUuid) =>
    `/third-party-payers/${thirdPartyPayerUuid}`;
  createThirdPartyPayer = () => `/third-party-payers/create`;
}

const monetAppRoutes = new MonetAppRoutes();
export const monetAppRouter = prependUri("/ia/monet")(monetAppRoutes);

class AdminAppRoutes {
  root = (): string => ``;
  openAgency = (): string => "/open-city";
  updateAgency = (): string => "/update-agency";
  updateZone = (): string => "/update-zone";
  updateMutualizedArea = (): string => "/update-mutualized-area";
}

const adminAppRoutes = new AdminAppRoutes();
export const adminAppRouter = prependUri("/ia/admin")(adminAppRoutes);

class CallEvaluationAppRoutes {
  root = (): string => ``;
  assessment = (uuid: string): string => `/${uuid}/assessment`;
  pricingStrategy = (uuid: string): string => `/${uuid}/pricing-strategy`;
  proclientIdentity = (uuid: string): string => `/${uuid}/proclient-identity`;
  scheduleVeb = (uuid: string): string => `/${uuid}/schedule-veb`;
}

const callEvaluationRoutes = new CallEvaluationAppRoutes();
export const callEvaluationAppRouter = prependUri("/ia/calls-evaluations")(callEvaluationRoutes);

class VebInterfaceAppRoutes {
  root = (): string => ``;
  proclientIdentity = (uuid: string): string => `/${uuid}/proclient-identity`;
  assessment = (uuid: string): string => `/${uuid}/assessment`;
  missionNeeds = (uuid: string): string => `/${uuid}/mission-needs`;
  cart = (uuid: string): string => `/${uuid}/cart`;
  financialHelp = (uuid: string) => `/${uuid}/financial-helps`;
  monetSettings = (uuid: string) => `/${uuid}/monet-settings`;
  warrantContract = (uuid: string) => `/${uuid}/warrant-contract`;
  prescribers = (uuid: string) => `/${uuid}/prescribers`;
}

const vebInterfaceAppRoutes = new VebInterfaceAppRoutes();
export const vebInterfaceAppRouter = prependUri("/ia/vebs-evaluations")(vebInterfaceAppRoutes);

// Metabase

class MetabaseAppRoutes {
  root = (): string => `?activeTabId=301`;
}

const metabaseAppRoutes = new MetabaseAppRoutes();
export const metabaseAppRouter = prependUri("/ia/metabase")(metabaseAppRoutes);

//
// Staffing
//

class StaffingAppRoutes {
  root = (): string => ``;
  view = (interventionId: string, params?: { caregiverUuid?: CaregiverUuid }): string => {
    if (params) {
      return `/${interventionId}?${toQueryString(params)}`;
    } else return `/${interventionId}`;
  };
}
const staffingAppRoutes = new StaffingAppRoutes();
export const staffingAppRouter = prependUri("/ia/staffing")(staffingAppRoutes);
