import styled from "styled-components";

import { Title } from "emoreg/atoms/Title";
import { Typography } from "emoreg/atoms/Typography";
import { color, spaces } from "emoreg/const";
import { Flex } from "emoreg";

const RawModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: ${spaces[24]};
  padding-top: 0;
`;

export const ModalFooter = ({ children }) => <RawModalFooter>{children}</RawModalFooter>;

const RawModalHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${spaces[24]};
  padding-bottom: 0;
`;

export const ModalHeader = ({ title, surtitle, subtitle, subtitleStyle }) => (
  <RawModalHeader>
    <Flex direction="column" gap={16}>
      {surtitle && <Typography fontWeight={600}>{surtitle}</Typography>}
      {title && <Title type="h2">{title}</Title>}
      {subtitle && (
        <Typography
          color={subtitleStyle?.color ?? color.grey.dark}
          fontWeight={subtitleStyle?.fontWeight}
        >
          {subtitle}
        </Typography>
      )}
    </Flex>
  </RawModalHeader>
);
