import { DateTime } from "luxon";

import {
  AnyAuditLog,
  AnyLog,
  CaregiverAuditLog,
  CaregiverLog,
  DebriefedEventLog,
  EventTargetEntity,
  isCaregiverAuditLog,
  isCaregiverLog,
  isPrescriberLog,
  isProclientLog,
  JsonValue,
  LogEntityType,
  PrescriberLog,
  ProclientLog,
} from "./types";

export const opt =
  <T>(reader: (JsonValue) => T) =>
  (json: JsonValue): T | null =>
    json ? reader(json) : null;

export const list =
  <T>(reader: (JsonValue) => T) =>
  (json: JsonValue): T[] =>
    (json || []).map(jsonItem => reader(jsonItem));

export const isoDateReader = (json: JsonValue): DateTime => DateTime.fromISO(json);
const dateReader = (json: JsonValue): DateTime => formattedDateReader(json, "yyyy-LL-dd");
const formattedDateReader = (json: JsonValue, format: string): DateTime =>
  DateTime.fromFormat(json, format);

const logReader = (json: JsonValue, logEntityType: LogEntityType): AnyLog => {
  if (json.logType === "AUDIT_LOG") {
    return auditLogReader(json, logEntityType);
  } else {
    return debriefedEventLogReader(json, logEntityType);
  }
};
/**
 * @description here is a primary function. It's inject the targetType passed inside each log.
 */
const logsReader = (json, logEntityType: LogEntityType): AnyLog[] =>
  list(passedJson => logReader(passedJson, logEntityType))(json);

export const caregiverLogsReader = (json): CaregiverLog[] =>
  logsReader(json, LogEntityType.caregiver).filter(isCaregiverLog);

export const caregiverAuditLogsReader = (json): CaregiverAuditLog[] =>
  logsReader(json, LogEntityType.caregiver).filter(isCaregiverAuditLog);
export const prescriberlogsReader = (json): PrescriberLog[] =>
  logsReader(json, LogEntityType.prescriber).filter(isPrescriberLog);
export const proclientlogsReader = (json): ProclientLog[] =>
  logsReader(json, LogEntityType.proclient).filter(isProclientLog);

const debriefedEventLogReader = (
  json: JsonValue,
  targetType: LogEntityType
): DebriefedEventLog => ({
  targetType: targetType,
  uuid: json.uuid,
  user: json.user,
  direction: json.direction,
  subject: json.subject,
  description: json.description,
  detailedReport: json.detailedReport,
  createdAt: DateTime.fromISO(json.createdAt),
  debriefedAt: DateTime.fromISO(json.debriefedAt),
  plannedAt: DateTime.fromISO(json.plannedAt),
  debriefedByDsitUuid: json.debriefedByDsitUuid,
  logType: "DEBRIEFED_EVENT",
  eventType: json.eventType,
  eventTypeExtended: json.eventTypeExtended,
  eventResult: json.eventResult,
  targetEntities: targetEntitiesReader(json.targetEntities),
  issuesUuids: json.issuesUuids,
  contacts: json.contacts,
});
// This is the reader for the "description" field of logs related to events
const eventReader = (json: JsonValue): JsonValue => ({
  eventCreatedAt: isoDateReader(json.eventCreatedAt || json.eventDatetime),
  eventPlannedAt: isoDateReader(json.eventPlannedAt || json.eventDatetime),
  eventSubject: json.eventSubject,
  eventType: json.eventType,
  eventTypeExtended: json.eventTypeExtended,
  eventResult: json.eventResult,
  eventUuid: json.eventUuid,
  eventVebSms: json.eventVebSms,
  cancellationReason: json.cancellationReason,
  cancellationReasonMore: json.cancellationReasonMore,
});
const readAuditIssueDescription = (json): JsonValue => {
  if (json.eventType?.includes("ISSUE")) {
    if (json.eventType === "CLOSE_ISSUE") {
      return {
        closedAt: opt(dateReader)(json.description.closedAt),
        closedByEventUuid: json.description.closedByEventUuid,
        title: json.description.title,
        issueUuid: json.description.issueUuid,
      };
    } else {
      return {
        happenedOn: opt(dateReader)(json.description.happenedOn),
        issueUuid: json.description.issueUuid,
        title: json.description.title,
        type: json.description.type,
      };
    }
  }
  if (json.eventType === "ARCHIVE") {
    return {
      goneReason: json.description.goneReason,
      goneReasonMore: json.description.goneReasonMore,
    };
  }
  if (json.eventType?.includes("EVENT")) {
    if (json.eventType === "CREATE_EVENT") {
      return eventReader(json.description);
    } else if (json.eventType === "DELETE_EVENT") {
      return eventReader(json.description);
    } else if (
      json.eventType === "UPDATE_EVENT" &&
      "before" in json.description &&
      "after" in json.description
    ) {
      return {
        after: eventReader(json.description.after),
        before: eventReader(json.description.before),
      };
    }
  }
  // TODO return a warning to enriched our readers
  return json.description;
};
const auditLogReader = (json: JsonValue, logEntityType: LogEntityType): AnyAuditLog => ({
  targetType: logEntityType,
  uuid: json.uuid,
  targetUuid: json.targetUuid,
  user: json.user,
  eventType: json.eventType,
  description: readAuditIssueDescription(json),
  createdAt: isoDateReader(json.createdAt),
  logType: "AUDIT_LOG",
});

const targetEntityReader = (json: JsonValue): EventTargetEntity => ({
  entityUuid: json.entityUuid,
  type: json.type,
  rootBinding: json.rootBinding,
});
export const targetEntitiesReader = list(targetEntityReader);
