import { Flex } from "emoreg";
import { Grid } from "emoreg/_deprecated/Grid";
import { Button } from "emoreg/atoms/Button";
import { Label } from "emoreg/atoms/Label";
import { spaces } from "emoreg/const";
import { AgencyFilter, agencyFilter, GeoFilter } from "hassibot/component/filter/geography";
import { GenericModalInjectedProps } from "hassibot/component/modal";
import {
  addSelectedStatusToAgencies,
  agencyInIdf,
} from "hassibot/component/modals/agency-filter-modal/agency-filter-modal.helpers";
import {
  BatchSelectorContainer,
  ClickableText,
} from "hassibot/component/modals/agency-filter-modal/agency-filter-modal.styles";
import { SelectableAgency } from "hassibot/component/modals/agency-filter-modal/agency-filter-modal.types";
import { useAppContext } from "hassibot/context";
import { splitArrayInChunks } from "hassibot/util/array";
import React, { useState } from "react";
import { ButtonWrapper } from "styles/atoms";
import { CheckboxLabeled } from "styles/molecules";
import { LargeModal } from "styles/organisms/modal";

const COLUMN_REPARTITION = 3;

type AgencyFilterModalProps = GenericModalInjectedProps<{ result: GeoFilter[] }> & {
  previouslySelectedAgencies: AgencyFilter[];
  onValidate: (selectedAgencies: AgencyFilter[]) => void;
};

export const AgencyFilterModal = ({
  previouslySelectedAgencies,
  hideModal,
  onValidate,
  ...rest
}: AgencyFilterModalProps) => {
  const [isPosting, setPosting] = useState(false);
  const { agencies: allAgencies } = useAppContext();

  const [agencies, setAgencies] = useState<SelectableAgency[]>(
    addSelectedStatusToAgencies(previouslySelectedAgencies, allAgencies)
  );

  const onSubmit = (): void => {
    setPosting(true);
    const selectedAgencies: AgencyFilter[] = agencies
      .filter(s => s.isSelected)
      .map(s => agencyFilter(s));
    onValidate(selectedAgencies);
  };

  const handleToggleOne = (agency: SelectableAgency): void => {
    setAgencies(prevState =>
      prevState.map(s => (s.uuid === agency.uuid ? { ...s, isSelected: !s.isSelected } : s))
    );
  };

  const handleSelectAll = (): void => {
    setAgencies(prevState => prevState.map(s => ({ ...s, isSelected: true })));
  };

  const handleUnselectAll = (): void => {
    setAgencies(prevState => prevState.map(s => ({ ...s, isSelected: false })));
  };

  const sortedAgenciesInsideIdf = agencies
    .filter(agency => {
      return agencyInIdf(agency);
    })
    .sort((agencyA, agencyB) => agencyA.name.localeCompare(agencyB.name));

  const sortedAgenciesOutsideIdf = agencies
    .filter(agency => {
      return !agencyInIdf(agency);
    })
    .sort((agencyA, agencyB) => agencyA.name.localeCompare(agencyB.name));

  const sortedChunkOfAgenciesOutsideIdf = splitArrayInChunks(
    sortedAgenciesOutsideIdf,
    COLUMN_REPARTITION
  );

  return (
    <LargeModal
      {...rest}
      id="agency-modal"
      title="Choix des agences"
      style={{ paddingTop: spaces[8] }}
      footer={
        <ButtonWrapper layout="opposed">
          <Button outline color="grey" onClick={() => hideModal()}>
            Annuler
          </Button>
          <Button color="blue" disabled={isPosting} onClick={() => onSubmit()}>
            Valider
          </Button>
        </ButtonWrapper>
      }
    >
      <Flex direction="column" gap={8}>
        <BatchSelectorContainer>
          <ClickableText onClick={() => handleSelectAll()}>Tout sélectionner</ClickableText>
          <ClickableText onClick={() => handleUnselectAll()}>Tout désélectionner</ClickableText>
        </BatchSelectorContainer>

        <Grid
          mobile={{ gridTemplateColumns: "1fr 1fr" }}
          desktop={{ gridTemplateColumns: "1fr 3fr" }}
        >
          <Flex direction="column" gap={4}>
            <Label>île-de-France</Label>
            {sortedAgenciesInsideIdf.map(agency => (
              <React.Fragment key={agency.uuid}>
                <CheckboxLabeled
                  key={agency.uuid}
                  id={agency.uuid}
                  checked={agency.isSelected}
                  label={agency.name}
                  onChange={() => handleToggleOne(agency)}
                />
              </React.Fragment>
            ))}
          </Flex>

          <Flex direction="column" gap={4}>
            <Label>Autres régions</Label>
            <Grid
              mobile={{ gridTemplateColumns: "1fr" }}
              desktop={{ gridTemplateColumns: "1fr 1fr 1fr" }}
            >
              {sortedChunkOfAgenciesOutsideIdf.map((column, index) => (
                <div key={index}>
                  {column.map(agency => (
                    <React.Fragment key={agency.uuid}>
                      <CheckboxLabeled
                        key={agency.uuid}
                        id={agency.uuid}
                        checked={agency.isSelected}
                        label={agency.name}
                        onChange={() => handleToggleOne(agency)}
                      />
                    </React.Fragment>
                  ))}
                </div>
              ))}
            </Grid>
          </Flex>
        </Grid>
      </Flex>
    </LargeModal>
  );
};
