import { BotInfo, BrowserInfo, NodeInfo, OperatingSystem } from "detect-browser";
import jwtDecode from "jwt-decode";
import { rem } from "polished";
import { ReactNode, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { digestGoogleAuthLogin } from "hassibot/services_v2/login";

import { color, font, radius, spaces } from "emoreg/const";
import appConfig from "hassibot/app-config";
import { XimiMigrationWarningAtConnection } from "hassibot/component/maintenance-mode/XimiMigrationWarning";
import { Emoji } from "styles/atoms";
import { messageFor } from "./oh-errors/error-messages";
import { Button } from "emoreg/atoms/Button";
import { Title } from "emoreg/atoms/Title";
import { Flex } from "emoreg/layouts";
import { Typography } from "emoreg/atoms/Typography";

/**
 *  In this file you will find the full-screen components that are not Hassibot.
 *  Things like 404, login, error, ...
 */
const StandaloneAppFrameStyle = styled.div`
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
const WrapperGoogleConnect = styled.div`
  flex: 0 1;
  height: ${rem(430)};
  border: 0.5px solid ${color.grey.base};
  border-radius: ${radius.large};
  padding: ${spaces[32]};
`;
const TypographyHighlight = styled.span`
  background: "#eee";
  padding: ${rem(3)} ${rem(6)};
  font-weight: ${font.weight.semiBold};
`;
const XimiMigrationWarning = styled.div`
  text-align: left;
  justify-content: left;
  align-items: left;
  margin-top: 16px;
  max-width: ${props => props.maxWidth};
  ul {
    list-style-type: disc;
    margin-block-start: 0px;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    margin-bottom: 0;
  }
`;

type StandalonAppFrameProps = {
  title: string;
  children?: ReactNode;
};

const StandaloneAppFrame = (props: StandalonAppFrameProps) => {
  const wrapperGoogleConnectRef = useRef<HTMLDivElement>(null);
  const [wrapperGoogleConnectWidth, setWrapperGoogleConnectWidth] = useState<string>();

  useEffect(() => {
    setWrapperGoogleConnectWidth(
      `${wrapperGoogleConnectRef?.current?.getBoundingClientRect().width}px`
    );
  }, []);

  return (
    <StandaloneAppFrameStyle>
      <WrapperGoogleConnect ref={wrapperGoogleConnectRef}>
        <Flex gap={16} direction={"column"}>
          {props.title && <Title type="h1">{props.title}</Title>}
          {props.children}
        </Flex>
      </WrapperGoogleConnect>
      {appConfig.IS_XIMI_MIGRATION_WARNING && wrapperGoogleConnectWidth && (
        <XimiMigrationWarning maxWidth={wrapperGoogleConnectWidth}>
          <XimiMigrationWarningAtConnection />
        </XimiMigrationWarning>
      )}
    </StandaloneAppFrameStyle>
  );
};

export const The404 = () => (
  <StandaloneAppFrame title={"Ooops, page non trouvé..."}>
    <Flex direction={"column"} gap={12}>
      <Typography>
        À transmettre à la tech'{" "}
        <TypographyHighlight>{window.location.pathname}</TypographyHighlight>
      </Typography>
      <Typography>
        <a href="/">Retour à l'application</a>
      </Typography>
    </Flex>
  </StandaloneAppFrame>
);

export const UnsupportedBrowser = () => (
  <StandaloneAppFrame title={"Votre navigateur n'est pas pris en charge !"}>
    <Typography>Rapprochez vous de l'équipe tech pour plus d'informations</Typography>
  </StandaloneAppFrame>
);

export const OldChromeBrowser = ({ browserOs }: { browserOs: OperatingSystem }) => {
  const text = "Mettez à jour votre version de Chrome pour accéder à Hassibot.";
  return (
    <StandaloneAppFrame title={"Votre navigateur est trop ancien !"}>
      <Typography>
        {browserOs === "Android OS" ? (
          <a href="https://play.google.com/store/apps/details?id=com.android.chrome&hl=fr">
            {text}
          </a>
        ) : (
          text
        )}
      </Typography>
    </StandaloneAppFrame>
  );
};

export const OldSafariBrowser = ({ browserOs }: { browserOs: OperatingSystem }) => {
  let toUpdate = "de Safari";
  if (browserOs === "iOS") {
    toUpdate = "d'iOS";
  } else if (browserOs === "Mac OS") {
    toUpdate = "de macOS";
  }
  return (
    <StandaloneAppFrame title={"Votre navigateur est trop ancien !"}>
      <Typography>Mettez à jour votre version {toUpdate} pour accéder à Hassibot.</Typography>
    </StandaloneAppFrame>
  );
};

export const WrongBrowserOnMobile = ({
  infos,
  platform,
}: {
  platform: OperatingSystem;
  infos: BrowserInfo | BotInfo | NodeInfo | null;
}) => (
  <StandaloneAppFrame title={"Mauvais navigateur !"}>
    {platform === "Android OS" ? (
      <Typography>
        Utilisez{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://play.google.com/store/apps/details?id=com.android.chrome"
        >
          Google Chrome
        </a>{" "}
        pour accéder à Hassibot sur Android.
      </Typography>
    ) : (
      <Typography>
        Utilisez{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://www.apple.com/fr/safari/">
          Safari
        </a>{" "}
        pour accéder à Hassibot sur iOS.
      </Typography>
    )}
    <details style={{ opacity: 0.2 }}>{JSON.stringify(infos)}</details>
  </StandaloneAppFrame>
);

export const BadRequest = () => (
  <StandaloneAppFrame title={"Ooops, mauvaise URL"}>
    <Typography>
      Pas besoin de transmettre à la tech, l'URL saisie n'est pas correcte <Emoji name="bomb" />.
    </Typography>
  </StandaloneAppFrame>
);

export const HoustonWeveGotAProblem = () => (
  <StandaloneAppFrame title={"Ooops, on a eu un problème..."}>
    <Typography>Veuillez réessayer, si le problème persiste, contactez la tech' </Typography>
    <Button
      onClickSyncBehavior
      color="blue"
      size="medium"
      onClick={() => {
        window.location.reload();
      }}
    >
      Recharger la page
    </Button>
  </StandaloneAppFrame>
);

const LoginError = ({ errorMessage }) => (
  <StandaloneAppFrame title={"Problème de connexion"}>
    <Typography>Veuillez transmettre ce message à la tech': {errorMessage}</Typography>
  </StandaloneAppFrame>
);

export const Loading = () => <StandaloneAppFrame title={"Chargement en cours..."} />;

export const LoginStandaloneApp = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  useEffect(() => {
    const listener = (e: any) => {
      // Not the message we're expecting
      if (!e.data.NIGOL_GOOGLE_JWT) {
        return;
      }

      const decoded = jwtDecode(e.data.NIGOL_GOOGLE_JWT);
      digestGoogleAuthLogin({
        mail: decoded.email,
        googleId: decoded.sub,
        googleJwt: e.data.NIGOL_GOOGLE_JWT,
      }).then(response => {
        if (response instanceof Error) {
          const error = messageFor()(response.message);
          setErrorMessage(error);
        }
      });
    };

    window.addEventListener("message", listener);
    return () => window.removeEventListener("message", listener);
  }, []);

  const modalWidth = Math.min(599, window.screen.width - 20),
    modalHeight = Math.min(600, window.screen.height - 30);

  const windowFeatures = `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no,width=${modalWidth},height=${modalHeight},top=${
    (window.screen.height - modalHeight) / 2
  },left=${(window.screen.width - modalWidth) / 2}`;
  const originUrl = btoa(encodeURIComponent(window.location.href));

  if (errorMessage) return <LoginError errorMessage={errorMessage} />;

  return (
    <>
      <StandaloneAppFrame title={"Connexion à Hassibot"}>
        <Button
          onClickSyncBehavior
          color="blue"
          size="medium"
          onClick={() => {
            window.open(
              `https://nigol.ouihelp.fr?originUrl=${originUrl}`,
              "LOGIN_WINDOW",
              windowFeatures
            );
          }}
        >
          Se connecter
        </Button>
      </StandaloneAppFrame>
    </>
  );
};
