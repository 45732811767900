import { AgencyFilter } from "hassibot/component/filter/geography/types";
import { SelectableAgency } from "hassibot/component/modals/agency-filter-modal/agency-filter-modal.types";
import { Agency } from "hassibot/services_v2/common/types";

export const addSelectedStatusToAgencies = (
  selection: AgencyFilter[],
  agencies: Agency[]
): SelectableAgency[] =>
  agencies.map(agency => {
    const isIncludedAgency = selection.findIndex(a => a.value.uuid === agency.uuid) !== -1;

    return { ...agency, isSelected: isIncludedAgency };
  });

const IDF_DEPARTMENT_CODES = ["75", "77", "78", "91", "92", "93", "94", "95"];

export const agencyInIdf = (agency: SelectableAgency): Boolean => {
  return IDF_DEPARTMENT_CODES.includes(agency.placeJson.citycode.slice(0, 2));
};
