export function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}

export function isTruthy<T>(value: T | false | "" | undefined | null): value is T {
  return !!value;
}

export const assertNumber = <T>(value: T | number): value is number => {
  return isDefined(value) && typeof value === "number";
};

export const assertString = <T>(value: T | string): value is string => {
  return isDefined(value) && typeof value === "string";
};
