import React from "react";
import styled from "styled-components";

import { useLegalMode } from "hassibot/current-legal-entity-provider";
import { Route } from "hassibot/route";
import { LegalMode } from "hassibot/services_v2/common/types";
import { Redirect, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import { responsiveHelpers as rh } from "styles/utils";

const BasicNavigationNav = styled.nav`
  ${({ navStyle }) => navStyle};
`;
const BasicNavigationList = styled.ul`
  margin-bottom: 0;
  display: flex;
  ${({ listStyle }) => listStyle};
`;
const BasicNavigationItem = styled.li`
  ${({ itemStyle }) => itemStyle};
  ${({ activeItemStyle }) => activeItemStyle};
`;
const MobileNavLabel = styled.span`
  display: none;
  ${rh.belowPortraitTablet`
  display: block;
  `};
`;
const NavLabel = styled.span`
  display: block;
  ${rh.belowPortraitTablet`${props => props.mobileLabel && "display: none"}`};
`;

export type NavItem = {
  label: string;
  onClick: () => void;
  isActive?: boolean;
  mobileLabel?: string;
};
type NavigationProps<T extends NavItem> = {
  navItems: T[];
  listStyle?: React.CSSProperties;
  itemStyle?: React.CSSProperties;
  navStyle?: React.CSSProperties;
  activeItemStyle?: React.CSSProperties;
  noMatchRedirectTo?: string;
};

/**
 * Unstyled navigation, default styled version to use is {@link MainNavigation}
 * for horizontal layout or {@link VerticalNavigation} for vertical one.
 */
export const BaseNavigation = ({
  navItems,
  listStyle,
  itemStyle,
  navStyle,
  activeItemStyle,
}: NavigationProps<NavItem>) => {
  return (
    <BasicNavigationNav navStyle={navStyle}>
      <BasicNavigationList listStyle={listStyle}>
        {navItems.map((navItem, index) => (
          <React.Fragment key={index}>
            <BasicNavigationItem
              activeItemStyle={activeItemStyle}
              isActive={navItem.isActive}
              itemStyle={itemStyle}
              onClick={navItem.onClick}
            >
              {navItem.mobileLabel && <MobileNavLabel>{navItem.mobileLabel}</MobileNavLabel>}
              <NavLabel mobileLabel={!!navItem.mobileLabel}>{navItem.label}</NavLabel>
            </BasicNavigationItem>
          </React.Fragment>
        ))}
      </BasicNavigationList>
    </BasicNavigationNav>
  );
};

export type NavigationWithRouterItem = Omit<NavItem, "isActive"> & {
  url: string;
  label: string;
  onClick: () => void;
  legalModes: LegalMode[];
  renderComponent?: (props: RouteComponentProps) => JSX.Element;
  exact?: boolean;
};

export type RouteMatchFn = (n: NavigationWithRouterItem, rcp: RouteComponentProps) => boolean;
const defaultRouteMatch = (
  navItem: NavigationWithRouterItem,
  rcp: RouteComponentProps
): boolean => {
  const localUrls = Array.isArray(navItem.url) ? navItem.url : [navItem.url];
  return navItem.exact
    ? localUrls.includes(rcp.location.pathname)
    : localUrls.some(localUrl => rcp.location.pathname.includes(localUrl));
};

/**
 * Unstyled router navigation, default styled version to use is {@link MainRouterNavigation}
 */
export const RouterNavigation = withRouter(
  ({
    navItems,
    routeMatch = defaultRouteMatch,
    noMatchRedirectTo,
    ...props
  }: NavigationProps<NavigationWithRouterItem> &
    RouteComponentProps & { routeMatch?: RouteMatchFn }) => {
    const { mode } = useLegalMode();
    const matchFn = routeMatch || defaultRouteMatch;
    const modeNavItems = navItems.filter(navItem => navItem.legalModes.includes(mode));
    const withActiveIndexModeNavItems = modeNavItems.map(navItem => ({
      ...navItem,
      isActive: matchFn(navItem, props),
    }));

    return (
      <>
        <BaseNavigation navItems={withActiveIndexModeNavItems} {...props} />
        <Switch>
          {navItems.map(({ url, renderComponent, exact, legalModes }) => (
            <Route
              key={JSON.stringify(url)}
              exact={exact}
              path={url}
              render={renderComponent}
              legalModes={legalModes}
            />
          ))}
          {noMatchRedirectTo ? <Redirect to={noMatchRedirectTo} /> : null}
        </Switch>
      </>
    );
  }
);
